<template>
	<div>
		<div class="friends-mobile" v-if="isMobile">
			<div class="banner posRelative">
				<div class="default-banner posAbsoluteAll" v-if="!banner.type"></div>
				<img :src="banner.url" class="posAbsoluteAll" draggable="false" v-if="banner.type===1">
				<video ref="bannerVideo" class="posAbsoluteAll video-div" :src="banner.url" :autoplay="true" loop="loop"
					:muted="isMuted" v-if="banner.type===2"></video>
				<div class="column-me title-text" :class="{'title-text-anim':showBannerAnim}" v-show="showBannerAnim">
					<span>{{banner.title}}</span>
					<span class="margin-top40">{{banner.sub_title}}</span>
				</div>
				<div v-if="banner.type===2">
					<img src="../assets/img/laba2.png" class="img-laba anim-bigger-small" v-if="isMuted"
						@click.stop="isMuted=false">
					<img src="../assets/img/laba1.png" class="img-laba anim-bigger-small" v-else
						@click.stop="isMuted=true">
				</div>
			</div>
			<div class="main-content">
				<div class="row-me flex-wrap">
					<div class="item-div column-me row-center" v-for="(item,index) in friendArray" :key="index"
						@click="clickItem(item)">
						<div class="img-div row-me center-all">
							<img :src="item.cover" class="img">
						</div>
						<div class="title margin-top20">{{item.firend}}</div>
					</div>
				</div>
			</div>
		</div>


		<div class="friends" v-else>
			<div class="banner posRelative">
				<div class="default-banner posAbsoluteAll" v-if="!banner.type"></div>
				<img :src="banner.url" class="posAbsoluteAll" draggable="false" v-if="banner.type===1">
				<video ref="bannerVideo" class="posAbsoluteAll video-div" :src="banner.url" :autoplay="true" loop="loop"
					:muted="isMuted" v-if="banner.type===2"></video>
				<div class="column-me title-text" :class="{'title-text-anim':showBannerAnim}" v-show="showBannerAnim">
					<span>{{banner.title}}</span>
					<span class="margin-top40">{{banner.sub_title}}</span>
				</div>
				<div v-if="banner.type===2">
					<img src="../assets/img/laba2.png" class="img-laba anim-bigger-small" v-if="isMuted"
						@click.stop="isMuted=false">
					<img src="../assets/img/laba1.png" class="img-laba anim-bigger-small" v-else
						@click.stop="isMuted=true">
				</div>
			</div>
			<div class="main-content">
				<div class="row-me flex-wrap">
					<div class="item-div column-me row-center" v-for="(item,index) in friendArray" :key="index"
						@click="clickItem(item)">
						<div class="img-div row-me center-all">
							<img :src="item.cover" class="img anim-bigger-small0">
						</div>
						<div class="title margin-top30">{{item.firend}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				banner: {},
				showBannerAnim: false,
				friendArray: [],
				language: '', //0中文，1英文
				isMuted: true, //视频是否静音
				isMobile: '', //0电脑，1手机
			}
		},
		watch: {
			navItem: {
				handler: function(newVal, oldVal) {
					this.getBanner()
					this.getData()
				}
			}
		},
		computed: {
			navItem() {
				return this.$store.state.navItem;
			},
		},
		created() {
			this.isMobile = sessionStorage.getItem("mobile") === '1'
			this.language = localStorage.getItem("language")
			document.title = this.navItem.name
			setTimeout(() => {
				this.showBannerAnim = true
			}, 1000)
			this.getBanner()
			this.getData()
		},
		activated() {
			this.language = localStorage.getItem("language")
			document.title = this.navItem.name
			this.showBannerAnim = false
			setTimeout(() => {
				this.showBannerAnim = true
			}, 1000)
		},
		mounted() {

		},
		methods: {
			clickItem(item) {
				window.open(item.link_url)
			},
			getBanner() {
				this.banner = {}
				let link_url = this.$store.state.navItem.link_url
				if (link_url == '/friends') {
					this.$postRequest('/web/navInfo', {
						id: this.$store.state.navItem.id
					}).then((res) => {
						if (res) {
							this.banner = res.data
						}
					}).catch((error) => {
						console.log(error)
					})
				}
			},
			//获取数据
			getData() {
				this.$postRequest('/web/friends', {}).then((res) => {
					if (res) {
						this.friendArray = res.data
					}
				}).catch((error) => {
					console.log(error)
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.friends-mobile {
		background-color: #F5F5F7;

		.banner {
			width: 100%;
			height: 35vh;

			.video-div {
				object-fit: cover;
			}

			.title-text {
				font-size: 25px;
				color: #FFFFFF;
				position: absolute;
				left: 20px;
				top: 30%;
				-ms-user-select: none;
				-webkit-user-select: none;
			}
		}

		.main-content {
			padding: 20px;

			.item-div {
				width: 47%;
				margin-bottom: 30px;
				margin-right: 20px;
				cursor: pointer;

				&:nth-child(2n) {
					margin-right: 0;
				}

				.img-div {
					width: 100%;
					height: 100px;
					background-color: #FFFFFF;

					.img {
						width: 100%;
						height: 100%;
						object-fit: scale-down;
					}
				}

				.title {
					width: 100%;
					font-size: 20px;
					color: #0B0F0F;
					text-align: center;
				}
			}
		}
	}


	.friends {
		background-color: #F5F5F7;

		.banner {
			width: 100%;
			height: 80vh;

			.video-div {
				object-fit: cover;
			}

			.title-text {
				font-size: 80px;
				color: #FFFFFF;
				position: absolute;
				left: 278px;
				top: 40%;
				-ms-user-select: none;
				-webkit-user-select: none;
			}
		}

		.main-content {
			padding: 100px 278px;

			.item-div {
				margin-bottom: 88px;
				margin-right: 52px;
				cursor: pointer;

				&:nth-child(3n) {
					margin-right: 0;
				}

				.img-div {
					width: 420px;
					height: 240px;
					background-color: #FFFFFF;

					.img {
						width: 380px;
						height: 200px;
						object-fit: scale-down;
					}
				}

				.title {
					width: 400px;
					font-size: 22px;
					color: #0B0F0F;
					text-align: center;
				}
			}
		}
	}
</style>
